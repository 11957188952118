import React from "react";

const About = () => {
  const calculate_age = () => {
    const dob = new Date(1996, 11, 20);
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-5">
          <div className="img-box dark-img-box">
            <div className="row g-2 justify-content-center">
              <div className="col-6">
                <img
                  src="img/about/1.jpg"
                  alt="about us"
                  className="rounded-3"
                  style={{
                    objectFit: "cover",
                    objectPosition: "20% 50%",
                    width: "100%",
                    height: "100%",
                    maxHeight: 300,
                    maxWidth: 200,
                  }}
                />
              </div>
              <div className="col-6">
                <img
                  src="img/about/2.jpeg"
                  alt="about us"
                  className="rounded-3"
                  style={{
                    objectFit: "cover",
                    objectPosition: "20% 50%",
                    width: "100%",
                    height: "100%",
                    maxHeight: 300,
                    maxWidth: 300,
                  }}
                />
              </div>
              <div className="col-6">
                <img
                  src="img/about/3.jpeg"
                  alt="about us"
                  className="rounded-3"
                  style={{
                    objectFit: "cover",
                    objectPosition: "20% 50%",
                    width: "100%",
                    height: "100%",
                    maxHeight: 300,
                    maxWidth: 300,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-7 ps-xl-5">
          <div className="typo-box about-me">
            <h3>MEF Development</h3>
            <h5>
              A <span className="color-theme">Web Developer</span> based in{" "}
              <span className="color-theme">Indonesia</span>
            </h5>
            <p>
              We design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and online stores. My passion is to design digital user
              experiences through the bold interface and meaningful
              interactions. Check out our Portfolio
            </p>
            <div className="row about-list">
              <div className="col-md-12">
                <div className="media">
                  <label>E-mail</label>
                  <p>elfazamuhammad1@gmail.com</p>
                </div>
                <div className="media">
                  <label>Phone</label>
                  <p>+62-812-1919-8307</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            {/* <div className="btn-bar">
                            <a className="px-btn px-btn-theme" href="img/resume.png" download>
                                Download CV
                            </a>
                        </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
